<template>
  <div>
    <div class="row flex-center h80">
      <img style="width: 2.64rem; height: .533333rem;" src="~images/surg.png" alt />
    </div>
    <good-list :list="recommends" @detail="onDetail"></good-list>
  </div>
</template>

<script>
import GoodList from "components/content/goodList/GoodList";

export default {
  name: "DetailRecomment",
  props: {
    recommends: {
      type: Array,
      default() {
        return [];
      }
    },
    currentIndex:0
  },
  methods: {
    onDetail(item) {
      this.$router.replace({
        path: "/detail",
        query: { goods_id: item.goods_id, index: this.currentIndex,item:JSON.stringify(item),q:this.$route.query.q }
      });
      location.reload();
    }
  },
  components: {
    GoodList
  }
};
</script>

<style scoped>
</style>
