<template>
    <div class="row flex-wrap between" style="padding: 0 .213333rem;">
        <div @click="onDetail(item)" class="column bg-white mb16" style="width: 4.68rem;" v-for="(item,index) in list" :key="index">
            <div>
                <img class="imgsize351" :src="item.image_url || item.goods_thumbnail_url || item.pict_url || 'http://img10.360buyimg.com/n1/'+item.imageUrl" alt="">
                <p class="ellipsis font24 f-1A1A1A h72 ml16 mr16">{{item.name || item.goods_name || item.short_title || item.wareName}}</p>
                <div class="row between baseline ml16 mr16 font24 f-F62341">
                    <div class="row font24 fontwb baseline mt20" >
                        <span>¥</span>
                        <span class="font32 ml5">{{item.price || item.min_group_price/100 || item.zk_final_price || item.price}}</span>
                    </div>
                    <span class="f-999999 line-through" v-if="item.min_normal_price || item.reserve_price">￥{{item.min_normal_price/100 || item.reserve_price}}</span>
                </div>
            </div>
            <div class="row mt16 mb20 ml16 mr16 font24 f-FFFFFF">
                <span class="row align-center fanli h25 bg-F62341 br5 mr16" v-if="(item.coupon_discount/100 || item.coupon_amount)">{{item.coupon_discount/100 || item.coupon_amount }}元券</span>
                <span class="row align-center fanli h25 bg-9240F7 br5" v-if="item.estimated_commission">省{{item.estimated_commission}}</span>
            </div>
        </div>
    </div>
</template>

<script>

export default {
  name: 'GoodList',
  props: {
    isJd:{
      type: Boolean,
      default: false
    },
    list:{
      type: Array,
      default() {
        return []
      }
    }
  },
  methods: {
    onDetail(item) {
      this.$emit('detail',item);
    }
  }
}
</script>

<style scoped>
    
</style>
