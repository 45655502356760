<template>
  <div class="detail">
    <!-- <title-top>商品详情</title-top> -->

    <div class="row position_r banner">
      <detail-swiper :banners="banners" class="w100"></detail-swiper>
      <img
        @click="onBack"
        class="imgsize48 position_a"
        style="left: .4rem; top: .666667rem;"
        src="~images/backy.png"
        alt
      />
    </div>
    <div class="row align-center between bg-white">
      <div class="row baseline mt33 mb20">
        <div class="row baseline font36 f-F62341 ml30">
          <span class="font24">到手价￥</span>
          <span class="fontwb">{{res.min_group_price/100 || res.zk_final_price || res.price}}</span>
        </div>
        <span
          class="font24 f-999999 ml25"
          v-if="(res.min_normal_price || res.reserve_price)"
        >原价￥{{res.min_normal_price/100 || res.reserve_price}}</span>
      </div>
      <div
        class="row align-center h40 font24 f-FFFFFF fanlbg mr30 mt33 mb20"
        v-if="res.estimated_commission"
      >省{{res.estimated_commission}}</div>
    </div>
    <div class="bg-white">
      <p
        class="font28 f-1A1A1A ellipsis ml30 mr30"
      >{{res.goods_name || res.short_title || res.wareName}}</p>
    </div>
    <!--优惠券-->
    <div
      class="row flex-center bg-white font32 f-F62341 position_r"
      v-if="(res.coupon_discount/100 || res.coupon_amount)"
    @click="onUrl">
      <img class="yhqsize mt40 mb32" src="~images/youhuiq.png" alt />
      <div class="row align-center position_a" style="top: .8rem; left: 1.733333rem;">
        <div class="row baseline">
          <span>￥</span>
          <span class="font89 fontwb">{{res.coupon_discount/100 || res.coupon_amount }}</span>
        </div>
        <div class="column ml16">
          <span>优惠券</span>
          <span class="font20" v-if="res.time">{{res.time}}</span>
        </div>
      </div>
    </div>
    <!--推荐-->
    <detail-recomment :recommends="recommends" :currentIndex="$route.query.index"></detail-recomment>
    <div class="h96"></div>
    <div class="row bg-white h98 position_f w100" style="bottom: 0; max-width: 750px;">
      <div class="flex1 row flex-around font22 f-666666">
        <div class="column" @click="onHome">
          <img class="imgsize44" src="~images/shouye_not.png" alt />
          <span>首页</span>
        </div>
        <div class="column" v-show="false">
          <img class="imgsize44" src="~images/shouc.png" alt />
          <span>收藏</span>
        </div>
      </div>
      <div class="flex1 column flex-center font32 f-FFFFFF bg-333333">
        <span>分享赚</span>
        <span class="font26" v-if="res.estimated_commission">￥{{res.estimated_commission}}</span>
      </div>
      <div class="flex1 column flex-center font32 f-FFFFFF bg-F62341" @click="onUrl">
        <span>购买省</span>
        <span
          class="font26"
          v-if="(res.coupon_discount/100 || res.coupon_amount)"
        >￥{{res.coupon_discount/100 || res.coupon_amount }}</span>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import {
  tbk_goods_detail,
  pinduoduo_goods_detail,
  pinduoduo_goods_promotion_url,
  jingdong_promotion_url,
  tbk_goods_search,
  jingdong_products,
  pinduoduo_goods_search,
  pingduoduo_top_goods
} from "network/igou.js";

import GoodList from "@/components/content/goodList/GoodList";

import DetailSwiper from "./childComps/DetailSwiper";
import DetailRecomment from "./childComps/DetailRecomment";

export default {
  name: "Detail",
  data() {
    return {
      res: {},
      time: "",
      banners: [],
      recommends: []
    };
  },
  mounted() {
    /*
    console.log('mounted');
    if (this.$route.query && this.$route.query.goods_id) {
      let goods_id = this.$route.query.goods_id;
      let index = this.$route.query.index;
      let item = JSON.parse(this.$route.query.item);
      this.res = item;
      console.log(item);
      if (index == 0 || index == 1) {
        this.banners = item.small_images.string;
      } else if (index == 2) {
        this.banners = ["http://img10.360buyimg.com/n1/" + item.imageUrl];
      } else if (index == 3) {
        this.banners = [item.goods_image_url];
      }
      this.initRecomments(this.$route.query.q);
    } else {
      this.$toast.show("该商品不存在");
      this.$router.back();
    }
    */
    
  },
  created() {this.init(this.$route.query);},

  components: {
    GoodList,
    DetailSwiper,
    DetailRecomment
  },
  methods: {
    init(query) {
      if (query && query.goods_id) {
      let goods_id = query.goods_id;
      let index = query.index;
      let item = JSON.parse(query.item);
      this.res = item;
      console.log(item);
      if (index == 0 || index == 1) {
        this.banners = item.small_images;
      } else if (index == 2) {
        this.banners = ["http://img10.360buyimg.com/n1/" + item.imageUrl];
      } else if (index == 3) {
        this.banners = [item.goods_image_url];
      }
      this.initRecomments(query.q);
    } else {
      this.$toast.show("该商品不存在");
      this.$router.back();
    }
    },

    //TODO 为你推荐
    initRecomments(q) {
      if (this.$route.query.index == 0) {
        tbk_goods_search(q).then(res => {
          this.recommends = res.result_list;
        });
      } else if (this.$route.query.index == 1) {
        tbk_goods_search(q, 1, 20, true).then(res => {
          this.recommends = res.result_list;
        });
      } else if (this.$route.query.index == 2) {
        jingdong_products().then(res => {
          this.recommends = res;
        });
      } else if (this.$route.query.index == 3) {
        if (q) {
          pinduoduo_goods_search(q).then(res => {
            this.recommends = res.data.goods_list;
          });
        } else {
          pingduoduo_top_goods().then(res => {
            this.recommends = res.data.list;
          });
        }
      }
    },
    initData(goods_id, index) {
      if (index == 0 || index == 1) {
        //淘宝
        tbk_goods_detail(goods_id).then(res => {
          console.log(res);
          let tmp = res.results[0];
          this.time = tmp.coupon_start_time + "至" + tmp.coupon_end_time;
        });
      } else if (index == 2) {
        //京东
      } else if (index == 3) {
        //拼多多
        pinduoduo_goods_detail(goods_id).then(res => {
          console.log(res);
          let tmp = res.data;
        });
      }
    },
    onHome() {
      this.$router.push("/home");
    },
    onBack() {
      this.$router.back();
    },
    onUrl() {
      let index = this.$route.query.index;
      if (index == 0 || index == 1) {
        //淘宝天猫推广链接
        if (this.res.coupon_share_url) {
          location.href = 'http:'+this.res.coupon_share_url;
        } else {
          location.href = this.res.data.item_url;
        }
      } else if (index == 2) {
        //京东
        let jdUrl = "https://item.jd.com/" + this.res.skuId + ".html";
        jingdong_promotion_url(jdUrl).then(res => {
          //console.log(res);
          location.href = res.data.click_url;
        });
      } else if (index == 3) {
        //拼多多
        pinduoduo_goods_promotion_url(this.res.goods_id).then(res => {
          //console.log(res.data.mobile_short_url);
          location.href = res.data.mobile_short_url;
        });
      }
    }
  }
};
</script>
<style  scoped>
.halftimg {
  width: 1.173333rem;
  height: 0.533333rem;
  background: url(~images/halftk.png) no-repeat;
  background-size: 1.173333rem 0.533333rem;
}
.fanlbg {
  background: url(~images/ygfl.png) no-repeat;
  background-size: 100% 0.533333rem;
  padding-left: 0.346667rem;
  padding-right: 0.173333rem;
}
.yhqsize {
  width: 9.146667rem;
  height: 2.213333rem;
}
</style>
